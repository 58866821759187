<template>
  <section class="about">
    <Header />
    <Advantage />
    <Tiles />
    <FBHL />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Advantage from "./Advantage";
import Tiles from "./Tiles";
import FBHL from "./FBHL";
export default {
  components: {
    Header,
    Advantage,
    Tiles,
    FBHL
  },
  metaInfo: {
    title: "About | WOW"
  },
  computed: {
    ...mapGetters(["getLiveProducts"])
  }
};
</script>

<style scoped lang="scss">
.about-main {
  @include grid($cols: 1fr, $row-gap: 20px, $col-gap: 50px);
  grid-template-areas:
    "head"
    "sub";
}
.about-head {
  grid-area: head;
}
.about-sub {
  grid-area: sub;
  @include grid($cols: 1fr, $row-gap: 10px);
}
.about-values__grid {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 25px, $align: center);
}
.about-values--value {
  @include grid($cols: 1fr, $row-gap: 15px);
  @include shadow();
  background: linear-gradient(135deg, $green, $black);
  padding: 20px;
  border-radius: 5px;
  * {
    color: #fff;
  }
}
@media (min-width: $sm) {
  .about-values__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $md) {
  .about-main {
    grid-template-columns: 2fr 1.75fr;
  }
}
@media (min-width: $lg) {
  .about-main {
    grid-template-columns: 1.25fr 2fr;
    grid-row-gap: 75px;
    padding: 50px 0;
  }
}
@media (min-width: $xl) {
  .about-main {
    padding: 75px 0;
  }
}
</style>